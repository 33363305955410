<template>
  <v-container>
    <v-container
      class="d-flex justify-space-between align-center mx-y px-0 my-3"
    >
      <span class="subtitle-1">Secciones de la encuesta</span>
      <v-btn
        v-if="permisoEscritura"
        color="primary"
        outlined
        small
        :disabled="loading"
        :loading="loading"
        @click="crearSeccion"
      >
        <v-icon small class="mr-2">mdi-plus</v-icon>
        <span>Agregar sección</span>
      </v-btn>
    </v-container>
    <span
      v-if="seccionesLocal && !seccionesLocal.length"
      class="text--secondary"
      >No se han agregado secciones a esta encuesta.</span
    >
    <v-list v-else>
      <draggable
        v-model="seccionesLocal"
        tag="div"
        ghost-class="ghost"
        handle=".handle"
        @change="cambiarOrden"
      >
        <template v-for="seccion in seccionesLocal">
          <v-list-item :key="seccion._id" class="item-seccion pl-0">
            <v-list-item-avatar class="mr-0" :class="{ handle: !loading }">
              <v-btn icon style="cursor: move" :disabled="loading">
                <v-icon>mdi-dots-grid</v-icon>
              </v-btn>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <router-link
                  :to="`${$route.fullPath}/seccion/${seccion._id}`"
                  class="link"
                  >{{ seccion.nombre }}
                </router-link>
              </v-list-item-title>
              <v-list-item-subtitle
                >Reactivos:
                {{
                  seccion && seccion.preguntas ? seccion.preguntas.length : "0"
                }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </template>
      </draggable>
    </v-list>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import {
  crearSeccionEncuesta,
  modificarOrdenSeccionEncuesta,
} from "./encuestas.service";

export default {
  props: {
    secciones: { type: Array, default: () => [] },
    permisoEscritura: { type: Boolean, default: false },
  },

  components: { draggable },

  data: () => ({
    seccionesLocal: [],
    loading: false,
  }),
  watch: {
    secciones() {
      this.seccionesLocal = this.secciones;
    },
  },

  mounted() {
    this.seccionesLocal = this.secciones;
  },

  methods: {
    async crearSeccion() {
      this.loading = true;

      try {
        const requestData = {
          nombre: "Sección sin título",
          idEncuesta: this.$route.params.idEncuesta,
        };

        const serverResponse = await crearSeccionEncuesta(requestData);
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else
          this.$router.push(
            `${this.$route.fullPath}/seccion/${serverResponse.seccion._id}`
          );
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    async cambiarOrden(event) {
      this.loading = true;

      try {
        const idEncuesta = this.$route.params.idEncuesta;
        const idSeccion = event.moved.element._id;
        const nuevoIndex = event.moved.newIndex;

        const requestData = { nuevoIndex };

        const serverResponse = await modificarOrdenSeccionEncuesta(
          idEncuesta,
          idSeccion,
          requestData
        );
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.ghost {
  opacity: 0.5;
  background: #e0e0e0;
}
</style>
