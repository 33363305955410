import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VContainer,[(!_vm.escuelaTienePermiso)?_c(VSkeletonLoader,{attrs:{"type":"list-item-two-line@5"}}):_c(VCard,{attrs:{"rounded":"lg","flat":""}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":"secondary","dark":"","flat":""}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push('/encuestas')}}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1),_c(VSpacer),_vm._v(" "+_vm._s(_vm.encuesta ? _vm.encuesta.nombre : "")+" "),_c(VSpacer),(_vm.permisoEscritura)?_c(VMenu,{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3221905750)},[_c(VList,_vm._l((_vm.menuEncuesta),function(menuEncuestaItem,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.menuAction(menuEncuestaItem)}}},[_c(VListItemTitle,[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(_vm._s(menuEncuestaItem.icon))]),_c('span',[_vm._v(_vm._s(menuEncuestaItem.text))])],1)],1)}),1)],1):_vm._e()],1),(_vm.loading || !_vm.encuesta)?_c(VCardText,[_c('loading-encuesta')],1):_c(VCardText,[_c(VContainer,[(!_vm.encuesta.descripcion || _vm.encuesta.descripcion === '<p></p>')?_c('div',{staticClass:"mb-3"},[_c('span',[_vm._v("No se ha capturado descripción.")])]):_c('div',{staticClass:"ck-content mb-3",domProps:{"innerHTML":_vm._s(_vm.encuesta.descripcion)}})]),_c('encuesta-secciones',{attrs:{"secciones":_vm.encuesta.secciones,"permisoEscritura":_vm.permisoEscritura}})],1),_c('usuariosEncuesta',{attrs:{"permisoEscritura":_vm.permisoEscritura},on:{"nuevoLog":_vm.nuevoLog}}),_c('logsComponent',{attrs:{"logs":_vm.encuesta.logs}})],1)],1),_c('crear-encuesta-dialog',{attrs:{"mostrar":_vm.mostrarModificacionEncuesta,"encuesta":_vm.encuesta},on:{"cancelar":function($event){_vm.mostrarModificacionEncuesta = false},"encuestaGuardada":_vm.encuestaGuardada,"nuevoLog":_vm.nuevoLog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }