<template>
  <div>
    <v-container>
      <v-skeleton-loader v-if="!escuelaTienePermiso" type="list-item-two-line@5"></v-skeleton-loader>
      <v-card v-else rounded="lg" flat>
        <v-toolbar dense color="secondary" dark flat class="text-h6">
          <v-btn icon @click="$router.push('/encuestas')"
            ><v-icon>mdi-arrow-left</v-icon></v-btn
          >
          <v-spacer></v-spacer> {{ encuesta ? encuesta.nombre : "" }}
          <v-spacer></v-spacer>
          <v-menu v-if="permisoEscritura" offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(menuEncuestaItem, index) in menuEncuesta"
                :key="index"
                @click="menuAction(menuEncuestaItem)"
              >
                <v-list-item-title>
                  <v-icon small class="mr-2">{{
                    menuEncuestaItem.icon
                  }}</v-icon>
                  <span>{{ menuEncuestaItem.text }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
        <v-card-text v-if="loading || !encuesta">
          <loading-encuesta></loading-encuesta>
        </v-card-text>
        <v-card-text v-else>
          <v-container>
            <div
              v-if="!encuesta.descripcion || encuesta.descripcion === '<p></p>'"
              class="mb-3"
            >
              <span>No se ha capturado descripción.</span>
            </div>
            <div
              v-else
              v-html="encuesta.descripcion"
              class="ck-content mb-3"
            ></div>
          </v-container>
          <encuesta-secciones
            :secciones="encuesta.secciones"
            :permisoEscritura="permisoEscritura"
          ></encuesta-secciones>
        </v-card-text>
        <usuariosEncuesta
          :permisoEscritura="permisoEscritura"
          @nuevoLog="nuevoLog"
        />
        <logsComponent :logs="encuesta.logs" />
      </v-card>
    </v-container>

    <crear-encuesta-dialog
      :mostrar="mostrarModificacionEncuesta"
      :encuesta="encuesta"
      @cancelar="mostrarModificacionEncuesta = false"
      @encuestaGuardada="encuestaGuardada"
      @nuevoLog="nuevoLog"
    ></crear-encuesta-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getEncuesta } from "./encuestas.service";
import loadingEncuesta from "./loadingEncuesta.vue";
import crearEncuestaDialog from "./crearEncuestaDialog.vue";
import encuestaSecciones from "./encuestaSecciones.vue";
import usuariosEncuesta from "./usuariosEncuesta.vue";
import logsComponent from "../widgets/logs.vue";
import { permisoMenuEscuela } from '../../helpers/utilerias';

export default {
  components: {
    "crear-encuesta-dialog": crearEncuestaDialog,
    "encuesta-secciones": encuestaSecciones,
    "loading-encuesta": loadingEncuesta,
    usuariosEncuesta,
    logsComponent,
  },

  computed: {
    ...mapGetters(["colors", "sessionToken", "menu"]),
  },

  data: () => ({
    loading: true,
    encuesta: {
      logs: [],
    },
    permisoEscritura: false,
    menuEncuesta: [
      {
        text: "Modificar nombre y descripción",
        action: "modificarEncuesta",
        icon: "mdi-square-edit-outline",
      },
    ],
    mostrarModificacionEncuesta: false,
    escuelaTienePermiso:false
  }),

  watch: {
    sessionToken(value) {
      if (value) {
        if (!permisoMenuEscuela("Encuestas")) this.$router.push("/dashboard");
        else {
          this.escuelaTienePermiso=true;
          this.getEncuesta();
          }
      }
    },
  },

  mounted() {
    if (this.sessionToken) {
      if (!permisoMenuEscuela("Encuestas")) this.$router.push("/dashboard");
      else {
        this.escuelaTienePermiso=true;
      this.getEncuesta();
      }
    }
  },

  methods: {
    async getEncuesta() {
      this.loading = true;

      try {
        const serverResponse = await getEncuesta(this.$route.params.idEncuesta);
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          this.$router.push("/encuestas");
        } else {
          this.encuesta = serverResponse.encuesta;
          this.permisoEscritura = serverResponse.permisoEscritura;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    menuAction(data) {
      switch (data.action) {
        case "modificarEncuesta":
          this.mostrarModificacionEncuesta = true;
          break;
      }
    },

    encuestaGuardada({ nombre, descripcion }) {
      this.encuesta.nombre = nombre;
      this.encuesta.descripcion = descripcion;
      this.mostrarModificacionEncuesta = false;
    },

    nuevoLog(nuevoLog) {
      this.encuesta.logs.unshift(nuevoLog);
    },
  },
};
</script>

<style>
@import url("../../classes/ck-content.css");
</style>
